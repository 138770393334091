import React from 'react';
import { Link } from 'gatsby';

import heroImage from '../../images/heroImage.png';

export const Hero = () => {
  return (
    <>
      <section className="pt-12 bg-gray-900 text-gray-100 sm:pt-16">
        <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
          <div className="text-center">
            <p className="max-w-5xl font-bold mx-auto mb-4 text-4xl leading-tight sm:leading-tight sm:text-5xl lg:text-6xl lg:leading-tight font-pj">
              Visually create and edit Tailwind CSS UIs 10x faster using AI.
            </p>
            <h1 className="max-w-2xl mx-auto px-6 text-lg text-gray-300 font-inter">
              Windframe is an AI-enhanced Visual tailwind builder and editor for rapidly prototyping
              and building stunning webpages using tailwind css. Speed up your web development
              process and ship in minutes not weeks.
            </h1>
            <a
              className="flex justify-center my-2 -mb-4"
              href="https://www.producthunt.com/posts/windframe-2-0-2?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-windframe&#0045;2&#0045;0&#0045;2"
              target="_blank" rel="noreferrer"
            >
              <img
                src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=425409&theme=light"
                style={{ width: '250px', height: '54px' }}
                width="250"
                height="54"
              />
            </a>
            <div className="px-8 sm:items-start sm:justify-center sm:px-0 sm:space-x-5 sm:flex mt-9">
             
              <a
                href="https://windframe.devwares.com"
                title=""
                className="mb-3 sm:mb-0 inline-flex items-center justify-center w-full px-8 py-3 text-lg font-bold text-white transition-all duration-200 bg-gray-800 border-2 border-transparent sm:w-auto rounded-xl font-pj hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900"
                role="button"
              >
                Get Started
              </a>
            </div>

           
          </div>
        </div>

        <div className="pb-12 bg-gray-900">
          <div className="relative">
            <div className="absolute inset-0 h-2/3 bg-gray-900"></div>
            <div className="absolute inset-0 h-2/3 bg-gray-900"></div>
            <div className="relative mx-auto">
              <div className="lg:max-w-6xl lg:mx-auto">
                <div className="mt-8 mx-auto w-fit relative group sm:mt-12 lg:mx-0">
                  <div
                    className="absolute opacity-70 -inset-px rounded-xl blur-lg group-hover:opacity-100 group-hover:-inset-1
                  duration-200 bg-gradient-to-r from-yellow-400 via-pink-500 to-blue-400"
                  ></div>
                  <video
                    autoPlay
                    muted
                    loop
                    className="feature__img inline-flex items-center justify-center text-base font-bold text-white px-1 py-1
                  bg-gray-900 border-transparent relative z-10 leading-7 transition-all duration-200 rounded-xl
                  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900 hover:bg-gray-600
                  focus:ring-offset-[#FFE942]"
                  >
                    <source
                      src={require('../../../components/images/windheader2.mp4')}
                      type="video/mp4"
                    />
                  </video>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
