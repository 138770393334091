import React from 'react';
import { StyledWrapper, Header, TabContent, Content } from './Tabs.styled';

export const Tabs = () => {
  const switchTab = function (evt, cityName) {
    // Declare all variables
    let i, tabcontent, tablinks, base;

    base = document.getElementsByClassName('base')[0];

    // Get all elements with class="tabcontent" and hide them
    tabcontent = base.querySelectorAll('.tabcontent');
    for (i = 0; i < tabcontent.length; i++) {
      tabcontent[i].style.display = 'none';
    }

    // Get all elements with class="tablinks" and remove the class "active"
    tablinks = base.querySelector('.nav-tabs').querySelectorAll('span');
    for (i = 0; i < tablinks.length; i++) {
      tablinks[i].classList.remove('active');
    }

    // Show the current tab, and add an "active" class to the button that opened the tab
    document.getElementById(cityName).style.display = 'block';
    evt.currentTarget.classList.add('active');
  };

  return (
    <section className="py-12 bg-gray-900 text-gray-100 sm:py-16 lg:py-20 xl:py-24">
      <div className="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
        <div className="">
          <div className="">
            <h2 className="text-2xl font-bold sm:text-3xl lg:text-4xl xl:text-5xl text-center">
              Radically improve your work productivity
            </h2>
            <p className="mt-8 text-lg font-normal text-gray-300 text-center">
              Easily get more done and ship projects to production in less time than ever before
            </p>

            <ul className="grid grid-cols-1 mt-8 sm:grid-cols-2 sm:mt-12 gap-x-12 gap-y-8 sm:gap-y-12">
              <li className="flex items-start">
                <svg
                  className="w-6 h-6 shrink-0"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5 13L9 17L19 7"
                    stroke="url(#paint0_linear_515_2603)"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <defs>
                    <linearGradient
                      id="paint0_linear_515_2603"
                      x1="5"
                      y1="7"
                      x2="20.3943"
                      y2="10.1196"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#6DDCFF" />
                      <stop offset="1" stopColor="#7F60F9" />
                    </linearGradient>
                  </defs>
                </svg>
                <div className="">
                  <h3 className="ml-3 mb-2 font-bold text-xl">Developer</h3>
                  <p className="ml-4 text-lg font-normal text-gray-300">
                    Create components, prototypes and webpages for projects needed at work or side
                    projects without worrying too much about the design. Build your UIs from scratch
                    on windframe or edit anyone of our regularly updated 1000+ premium template
                    blocks.
                  </p>
                </div>
              </li>

              <li className="flex items-start">
                <svg
                  className="w-6 h-6 shrink-0"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5 13L9 17L19 7"
                    stroke="url(#paint0_linear_515_2603)"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <defs>
                    <linearGradient
                      id="paint0_linear_515_2603"
                      x1="5"
                      y1="7"
                      x2="20.3943"
                      y2="10.1196"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#6DDCFF" />
                      <stop offset="1" stopColor="#7F60F9" />
                    </linearGradient>
                  </defs>
                </svg>
                <div className="">
                  <h3 className="ml-3 mb-2 font-bold text-xl">Startup</h3>
                  <p className="ml-4 text-lg font-normal text-gray-300">
                    Ship components, webpages for your startup to production in minutes and get in
                    front of your users faster using windframe. You can easily build landing
                    pages,About pages, product pages and so much more with very minimal effort using
                    windframe while also saving countless of hours of design and development
                  </p>
                </div>
              </li>

              <li className="flex items-start">
                <svg
                  className="w-6 h-6 shrink-0"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5 13L9 17L19 7"
                    stroke="url(#paint0_linear_515_2603)"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <defs>
                    <linearGradient
                      id="paint0_linear_515_2603"
                      x1="5"
                      y1="7"
                      x2="20.3943"
                      y2="10.1196"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#6DDCFF" />
                      <stop offset="1" stopColor="#7F60F9" />
                    </linearGradient>
                  </defs>
                </svg>
                <div className="">
                  <h3 className="ml-3 mb-2 font-bold text-xl">Agency</h3>
                  <p className="ml-4 text-lg font-normal text-gray-300">
                    Empower your team to build faster and ship high quality designed projects to
                    your clients. Windframe allows your entire team to jump in and collaborate
                    together on projects, make prototypes easily and decide on designs quickly
                    together. Invite team members as editors to projects and let them build
                    together.
                  </p>
                </div>
              </li>

              <li className="flex items-start">
                <svg
                  className="w-6 h-6 shrink-0"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5 13L9 17L19 7"
                    stroke="url(#paint0_linear_515_2603)"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <defs>
                    <linearGradient
                      id="paint0_linear_515_2603"
                      x1="5"
                      y1="7"
                      x2="20.3943"
                      y2="10.1196"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#6DDCFF" />
                      <stop offset="1" stopColor="#7F60F9" />
                    </linearGradient>
                  </defs>
                </svg>
                <div className="">
                  <h3 className="ml-3 mb-2 font-bold text-xl">Designer</h3>
                  <p className="ml-4 text-lg font-normal text-gray-300">
                    Design pages on windframe that you can easily export to code without having to
                    write all the code from scratch. Put together elements and components together
                    the way you want them and export to code easily using a very familiar interface
                    like other design tools such as figma and framer.
                  </p>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};
