import React from 'react';


export const YoutubePreview = () => {
  return (
    <div className="container bg-gray-900 text-gray-100">
      <div className="hero my-5 py-5">
        <h2 className="font-weight-bold text-center mb-4 text-3xl font-bold">Tutorials</h2>
        <h5 className="text-center mb-5 mx-auto" style={{ maxWidth: '720px' }}>
          Learn how to use Windframe to create stunning tailwind css web application by mastering it tools
        </h5>
        <section className="bg-gray-800 rounded-xl p-10 w-full space-y-8">
          <ul className="list-none flex items-start justify-center flex-wrap lg:justify-start lg:flex-nowrap gap-10">
            <li className="w-full flex-1 flex-shrink-0 space-y-4">
              <iframe src="https://www.youtube.com/embed/JsFGpTuwezU" title="How to add elements in Windfrmae" className="w-full h-auto rounded-lg" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              <h3 className="text-xl font-medium">How to add elements in Windframe</h3>
            </li>
            <li className="w-full flex-1 flex-shrink-0 space-y-4">
              <iframe src="https://www.youtube.com/embed/48VBbmYH6PA" title="How to import templates and components in Windframe" className="w-full h-auto rounded-lg" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              <h3 className="text-xl font-medium">How to import templates and components in Windframe</h3>

            </li>
            <li className="w-full flex-1 flex-shrink-0 space-y-4">
              <iframe src="https://www.youtube.com/embed/oWbUrAdAr3s" title="How to add and reuse your custom templates in Windframe" className="w-full h-auto rounded-lg" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              <h3 className="text-xl font-medium">How to add and reuse your custom templates in Windframe</h3>
            </li>

          </ul>
        </section>

      </div>
    </div>
  );
};
