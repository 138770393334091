import React from 'react';
import { Link } from 'gatsby';


export const BlocksAndFullPages = () => {
  return (
    <div className="container bg-gray-900 text-gray-100">
      <div className="hero my-5 py-5">
        <h2 className="font-weight-bold text-center mb-4 text-3xl font-bold">Block and Full Page  Templates</h2>
        <h5 className="text-center mb-5 mx-auto" style={{ maxWidth: '720px' }}>
          Kickstart your project using some of 1000+ collection of professionally crafted blocks and full-page templates
          built with Tailwind CSS 👌
        </h5>
        <section className="space-y-16">
          <div className="bg-gray-800 rounded-xl p-10 w-full space-y-8">
            <div className="flex items-center justify-between flex-col sm:flex-row ">
              <h3 className="text-white font-medium text-xl mb-3 sm:mb-0">
                Windframe Block Templates
              </h3>
              <Link to="/windframe/blocks/">
                View All ⇾
              </Link>
            </div>
            <ul className="list-none flex items-start justify-center flex-wrap lg:justify-start lg:flex-nowrap gap-10">
              <li className="cursor-pointer bg-gray-900 p-2 rounded-lg drop-shadow-md">
                <Link to="/windframe/blocks/">
                  <img
                    src="https://devwares-pull-zone.b-cdn.net/windframe/selectorImages/headerLight02.png"
                    alt="block templates preview"
                    className="w-[16rem] h-[10rem] object-contain bg-white bg-center rounded-md "
                  />
                </Link>
              </li>
              <li className="cursor-pointer bg-gray-900 p-2 rounded-lg drop-shadow-md">
                <Link to="/windframe/blocks/">
                  <img
                    src="https://devwares-pull-zone.b-cdn.net/windframe/selectorImages/teamdark.png"
                    alt="block templates preview"
                    className="w-[16rem] h-[10rem] object-contain bg-black bg-center rounded-md "
                  />
                </Link>
              </li>
              <li className="cursor-pointer bg-gray-900 p-2 rounded-lg drop-shadow-md">
                <Link to="/windframe/blocks/">
                  <img
                    src="https://devwares-pull-zone.b-cdn.net/windframe/selectorImages/content/newcontent34.jpg"
                    alt="block templates preview"
                    className="w-[16rem] h-[10rem] object-contain bg-white bg-center rounded-md "
                  />
                </Link>
              </li>
              <li className="cursor-pointer bg-gray-900 p-2 rounded-lg drop-shadow-md">
                <Link to="/windframe/blocks/">
                  <img
                    src="https://devwares-pull-zone.b-cdn.net/windframe/selectorImages/featuresdark.png"
                    alt="block templates preview"
                    className="w-[16rem] h-[10rem] object-contain bg-black bg-center rounded-md "
                  />
                </Link>
              </li>
            </ul>
          </div>
          <div className="bg-gray-800 rounded-xl p-10 w-full space-y-8">
            <div className="flex items-center flex-col sm:flex-row  justify-between">
              <h3 className="text-white font-medium text-xl mb-3 sm:mb-0">
                Windframe Full Page Templates
              </h3>
              <Link to="/windframe/full-pages/">
                View All ⇾
              </Link>
            </div>

            <ul className="list-none flex items-start justify-center flex-wrap lg:justify-start lg:flex-nowrap gap-10">
              <li className="w-[16rem] h-[12rem] cursor-pointer bg-gray-900 p-2 rounded-lg drop-shadow-md overflow-hidden">
                <Link to="/windframe/full-pages/">
                  <img
                    src="https://devwares-pull-zone.b-cdn.net/windframe/selectorImages/fullpages/localhost_3000_editor%20(5).png"
                    alt="block templates preview"
                    className="w-full h-auto object-contain bg-white bg-bottom rounded-md "
                  />
                </Link>
              </li>
              <li className="w-[16rem] h-[12rem] cursor-pointer bg-gray-900 p-2 rounded-lg drop-shadow-md overflow-hidden">
                <Link to="/windframe/full-pages/">
                  <img
                    src="https://devwares-pull-zone.b-cdn.net/windframe/selectorImages/fullpages/localhost_3000_editor%20(8).png"
                    alt="block templates preview"
                    className="w-full h-auto object-contain bg-black bg-bottom rounded-md "
                  />
                </Link>
              </li>
              <li className="w-[16rem] h-[12rem] cursor-pointer bg-gray-900 p-2 rounded-lg drop-shadow-md overflow-hidden">
                <Link to="/windframe/full-pages/">
                  <img
                    src="https://devwares-pull-zone.b-cdn.net/windframe/selectorImages/fullpages/localhost_3000_editor%20(7).png"
                    alt="block templates preview"
                    className="w-full h-auto object-contain bg-white bg-bottom rounded-md "
                  />
                </Link>
              </li>

            </ul>
          </div>
        </section>
      </div>
    </div>
  );
};
