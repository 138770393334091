import React from 'react';
import { Link } from 'gatsby';


export const Cta = () => {
  return (
    <div className="text-light py-5 bg-gray-800">
      <div className="container">
        <div className="hero mt-5">
          <p className="text-center mb-1">
            Create beautiful websites, prototypes and designs using an intuitve editor and builder
          </p>
          <h1 className="text-center mb-5 mx-auto" style={{ maxWidth: '800px', fontSize: '3rem' }}>
            Start visually creating webpages using windframe
          </h1>
          <div className="hero__buttons text-center mt-5">
            <Link
              href="https://windframe.devwares.com"
              className="inline-flex items-center justify-center w-full px-8 py-3 text-lg font-bold text-gray-900 hover:text-white transition-all duration-200 bg-gray-100 border-2 border-gray-900 sm:w-auto rounded-xl font-pj hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900"
            >
              <span className="btn__text">Start creating for free</span>
            </Link>
          </div>

          {/* <p className="text-center mt-2">View full <Link to="/windframe">documentation here</Link></p> */}
        </div>
      </div>
    </div>
  );
};
