import React from 'react';
import Helmet from 'react-helmet';

import AppContext, { UserContext } from '../../components/AppContext';
import NavBar from '../../components/NavBar/WindframeNav';
import { Footer } from '../../components/Footer/WindframeFooter';
import { VideoSection } from '../../components/Windframe/Windframe/VideoSection';
import { Hero } from '../../components/Windframe/Windframe/Hero';
import { Cta } from '../../components/Windframe/Windframe/Cta';

import { Hero2 } from '../../components/Windframe/Windframe/Hero2';
import { Hero0 } from '../../components/Windframe/Windframe/Hero0';
import { ImportHero } from '../../components/Windframe/Windframe/ImportHero';
import { Hero03 } from '../../components/Windframe/Windframe/Hero03';

// import { Hero3 } from '../../components/Windframe/Windframe/Hero3';
import { Hero4 } from '../../components/Windframe/Windframe/Hero4';
import { HeroCodeEditor } from '../../components/Windframe/Windframe/HeroCodeEditor';

import { HeroClasses } from '../../components/Windframe/Windframe/HeroClasses';

import { Hero5 } from '../../components/Windframe/Windframe/Hero5';
// import { Hero6 } from '../../components/Windframe/Windframe/Hero6';
import { Tabs } from '../../components/Windframe/Windframe/Tabs';

import { Preview } from '../../components/Windframe/Windframe/Preview';
import { Testimonial } from '../../components/FormBuilder/Testimonial';
import { Feature } from '../../components/Windframe/Windframe/Feature';
// import { DocsPreview } from '../../components/Windframe/Windframe/DocsPreview';
import { BlocksAndFullPages } from '../../components/Windframe/Windframe/Blocks';
import { YoutubePreview } from '../../components/Windframe/Windframe/YoutubePreview';
import { Upcoming } from '../../components/Windframe/Windframe/Upcoming';
import { FeaturedIn } from '../../components/NewPage/React/FeaturedIn';
import { Stats } from '../../components/FormBuilder/Stats';
import { Supports } from '../../components/FormBuilder/Supports';
import { DashboardBlock } from '../../components/Windframe/Windframe/DashboardBlock';
import { WindframeAIBlock } from '../../components/Windframe/Windframe/WindframeAIBlock';
import '../../styles/scss/style.scss'

// import FAQ from '../../components/Windframe/Windframe/Faq';

const Windframe = () => {
  return (
    <AppContext>
      <Helmet>
        <title>
          Windframe - Advanced Tailwind CSS page builder | Tailwind builder | Tailwind Editor |
          Visual Builder
        </title>
        <meta
          name="title"
          content="Windframe - Advanced Tailwind CSS page builder | Tailwind builder | Tailwind Editor | Visual Builder"
        />
        <meta
          name="description"
          content="Create breathtaking websites, webpages and tailwind templates using our tailwind css builder / Tailwind Editor in record time with over 200+ easily editable tailwind templates blocks"
        />
        <meta
          property="og:title"
          content="Windframe - Advanced Tailwind CSS page builder | Tailwind builder | Tailwind Editor | Visual Builder"
        />
        <meta
          property="og:description"
          content="Create breathtaking websites, webpages and tailwind templates using our tailwind css builder / Tailwind Editor in record time with over 200+ easily editable tailwind templates blocks"
        />
        <meta
          property="twitter:title"
          content="Windframe - Advanced Tailwind CSS page builder | Tailwind builder | Tailwind Editor | Visual Builder"
        />
        <meta
          property="twitter:description"
          content="Create breathtaking websites, webpages and tailwind templates using our tailwind css builder / Tailwind Editor in record time with over 200+ easily editable tailwind templates blocks"
        />
        <link rel="canonical" href="https://www.windframe.dev/" />
        <meta property="og:url" content="https://www.windframe.dev/" />
        <meta property="og:site_name" content="Devwares" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          property="og:image"
          content="https://devwares-pull-zone.b-cdn.net/windframe-poster-image-1jj.png"
        />
        <meta
          name="twitter:image"
          content="https://devwares-pull-zone.b-cdn.net/windframe-poster-image-1jj.png"
        />
      </Helmet>
      <UserContext.Consumer>
        {({ user }) => (
          <div className="bg-gray-900 text-gray-100">
            {/* <div className="w-full h-10 px-6 flex items-center justify-center bg-orange-500 text-white">
              <p className="text-xl">
                We are live on product hunt today 🚀 and would appreciate your support!
              </p>
              {/* <a href="https://www.producthunt.com/posts/windframe-2-0-2" className="">
                <img
                  src="https://assets.stickpng.com/images/580b57fcd9996e24bc43c534.png"
                  alt="ad-banner"
                  className="rounded-full w-10 h-10"
                />
              </a> */}
              {/* <a
                href="https://www.producthunt.com/posts/windframe-2-0-2"
                className="px-3 text-white underline text-xl font-bold !underline"
                style={{ textDecoration: 'underline' }}
              >
                Support on Product Hunt
              </a>
            </div>  */}
            <div className="container">
              <NavBar user={user} dark={true} />
            </div>
            <Hero />
            <Preview />
            <br />
            <br />
            <FeaturedIn />
            <Hero2 />
            <WindframeAIBlock />
            <ImportHero />
            <DashboardBlock />
            <VideoSection />
            <Supports topText="Export code to" />
            <Hero4 />
            <HeroCodeEditor />
            <HeroClasses />
            <Hero0 />
            <Tabs />
            <Feature />
            <Hero5 />
            <Hero03 />
            <Stats />
            <Upcoming />
            <BlocksAndFullPages />
            <YoutubePreview />
            <Testimonial />
            <Cta />
            <div className="container">
              <Footer />
            </div>
          </div>
        )}
      </UserContext.Consumer>
    </AppContext>
  );
};

export default Windframe;
