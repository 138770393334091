import React from 'react';

export const DashboardBlock = () => {
  return (
    <section className="py-12 bg-gray-900 text-gray-100 sm:py-16 lg:py-20">
      
      <div className="px-4 mx-auto container px-6">
        <div className="grid grid-cols-1 lg:items-center gap-y-8 lg:grid-cols-2 md:gap-y-16 lg:gap-x-16">
          <div>
            <img
              className=""
              src={require('../../../components/images/windframeDashboard.png')}
              alt="Tailwind css dashboard builder"
            />
          </div>
          <div>
            <div className="relative w-fit mt-8 sm:mt-12">
              <div className="absolute -inset-5">
                <div
                  className="w-full h-full max-w-sm mx-auto lg:mx-0 opacity-30 blur-lg filter  
                    bg-gradient-to-r
                    from-yellow-400
                    via-pink-500
                    to-green-600"
                ></div>
              </div>
            </div>
            <div className="lg:text-left">
              <h2 className="text-3xl font-bold sm:text-4xl xl:text-5xl font-pj">
                Build stunning dashboards and admin web apps
              </h2>
              <p className="mt-4 text-base leading-7 text-gray-300 font-pj sm:mt-8">
                Create stunning and functional tailwind css dashboards and admin apps in no time
                using our tailwind css builder and editor.
              </p>
              <p className="mt-4 text-base leading-7 text-gray-300 font-pj sm:mt-8">
                Easily customize your dashboard with a range of widgets and charts. Choose from a
                variety of stunning pre-designed templates, or start from scratch and create your
                own unique design. With our powerful editing tools, you can easily tweak and
                fine-tune your design until it's just right.
              </p>
            </div>

         
          </div>

        </div>
      </div>
    </section>
  );
};
