import React from 'react';

import image from '../../images/first-step2.png';
import image2 from '../../images/second-step2.png';
import image3 from '../../images/third-step2.png';

export const Preview = () => {
  return (
    <div className="container px-4 lg:px-8 bg-gray-900 text-gray-100">
      <div className="mt-32">
        <h1 className="text-center mb-6 text-4xl font-bold">How it works</h1>

        <div className="grid grid-cols-1 gap-3 mt-8 sm:grid-cols-3 lg:mt-8 lg:gap-x-8">
          <div className="transition-all duration-200 bg-gray-900 rounded-xl text-gray-100 hover:shadow-xl">
            <div className="py-10 px-9">
              <img alt="Build from scratch or select prebuilt tailwind templates" src={image} />
              <h3 className="mt-8 text-lg font-semibold">
                Build from scratch or select prebuilt tailwind templates
              </h3>
              <p className="mt-4 text-base text-gray-300">
                Choose from a directory of 1000+ tailwind css templates. These tailwind css blocks
                were professionally crafted to fit different needs ranging from landing, saas,
                portfolio, about us, contact us etc. and are constantly being updated with new
                website templates
              </p>
            </div>
          </div>

          <div className="transition-all duration-200 bg-gray-900 rounded-xl text-gray-100 hover:shadow-xl">
            <div className="py-10 px-9">
              <img alt="Build from scratch or select prebuilt tailwind templates" src={image2} />
              <h3 className="mt-8 text-lg font-semibold">
                Create or Customize the designs with numerous options
              </h3>
              <p className="mt-4 text-base text-gray-300">
                Preview your designs in the tailwind builder to see how they render on a webpage and
                save them easily when you are done. You can come back when you wish to continue
                working on it or export the code from your designs to use elsewhere or host on your
                own server
              </p>
            </div>
          </div>

          <div className="transition-all duration-200 bg-gray-900 rounded-xl text-gray-100 hover:shadow-xl">
            <div className="py-10 px-9">
              <img alt="Build from scratch or select prebuilt tailwind templates" src={image3} />
              <h3 className="mt-8 text-lg font-semibold">Preview designs, save and export code</h3>
              <p className="mt-4 text-base text-gray-300">
                Preview your designs in the tailwind builder to see how they render on a webpage and
                save them easily when you are done. You can come back when you wish to continue
                working on it or export the code from your designs to use elsewhere or host on your
                own server
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
