import React from 'react';

export const WindframeAIBlock = () => {
  return (
    <section className="py-12 bg-gray-900 text-gray-100 sm:py-16 lg:py-20">
      
      <div className=" mx-auto container px-6">
        <div className="grid grid-cols-1 lg:items-center gap-y-8 lg:grid-cols-2 md:gap-y-16 lg:gap-x-16">
          <div>
            <div className="relative mx-auto">
              <div className="lg:max-w-6xl lg:mx-auto">
                <div className=" mx-auto w-fit relative group sm:mt-12 lg:mx-0">
                  <div className="absolute opacity-70 -inset-px rounded-xl blur-lg group-hover:opacity-100 group-hover:-inset-1
                  duration-200 bg-gradient-to-r from-yellow-400 via-pink-500 to-blue-400"></div>
                  <video autoPlay muted loop className="feature__img inline-flex items-center justify-center text-base font-bold text-white px-1 py-1
                  bg-gray-900 border-transparent relative z-10 leading-7 transition-all duration-200 rounded-xl
                  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900 hover:bg-gray-600
                  focus:ring-offset-[#FFE942] origin-left rotate-2">
                    <source
                      src={require('../../../components/images/aivideo.mp4')}
                      type="video/mp4"
                    />
                  </video>
                </div>
              </div>
            </div>

          </div>
          <div>
            <div className="lg:text-left">
              <h2 className="text-3xl font-bold sm:text-4xl xl:text-5xl font-pj">
                Generate and edit UIs using Windframe AI
              </h2>
              <p className="mt-4 text-base leading-7 text-gray-300 font-pj sm:mt-8">
                Create stunning UIs in seconds with Windframe AI. Import your components and let AI handle tasks like responsive design, element alignment, and dark/light mode variants.
                </p>
                <p className="mt-4 text-base leading-7 text-gray-300 font-pj sm:mt-8">
                  Windframe AI ensures design consistency across projects by generating consistent templates from a single master template. With visual editing capabilities, you can quickly iterate and fine-tune your designs, saving you time.
                </p>
              <p className="mt-4 text-base leading-7 text-gray-300 font-pj sm:mt-8">
                  Experience the future of UI design with Windframe AI. Unlock endless possibilities for creating beautiful and cohesive designs effortlessly.
                </p>
            </div>

            <div className="relative w-fit mt-8 sm:mt-12">
              <div className="absolute -inset-5">
                <div
                  className="w-full h-full max-w-sm mx-auto lg:mx-0 opacity-30 blur-lg filter  
                    bg-gradient-to-r
                    from-yellow-400
                    via-pink-500
                    to-green-600"
                ></div>
              </div>
              <a
                href="https://windframe.dev/docs/ai-experiments"
                title=""
                className="relative z-10 inline-flex items-center justify-center w-full px-8 py-3 text-lg font-bold text-white transition-all duration-200 bg-gray-900 border-2 border-transparent sm:w-auto rounded-xl font-pj hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900"
                role="button"
                target="_blank"
                rel="noreferrer"
              >
                See AI in action
              </a>
            </div>
          </div>

        </div>
      </div>
    </section>
  );
};
