import React from 'react';
import styled from 'styled-components';

export const CardWrapper = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: repeat(8, 1fr);
  gap: 0px;
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    grid-gap: 2.5rem;
  }
`;

export const ImageWrapper = styled.div`
  width: 5rem;
  height: 5rem;
  margin: 0 auto;
  margin-top: 1px;
  grid-column: span 2 / span 2;

  img {
    width: 100%;
    height: 100%;
    border-radius: 0.375rem;
    object-fit: cover;
  }
  @media (max-width: 768px) {
    grid-column: span 1 / span 1;
  }
`;

export const TextWrapper = styled.div`
  grid-column: span 8 / span 8;

  @media (max-width: 768px) {
    grid-column: span 1 / span 1;
  }
`;

export const Lead = styled.p`
  font-weight: 400;
  font-size: 1.3rem;
  line-height: 1.75rem;
  margin-bottom: 1rem;
`;

export const Name = styled.p`
  font-weight: 600;
  font-size: 1.1rem;
  line-height: 1.5rem;
  margin-bottom: 3rem;
  color: rgb(29 78 216);
`;

export const Testimonial = () => {
  return (
    <section className="py-12 bg-gray-900 text-gray-100 sm:py-16 lg:py-20 xl:py-24">
      <div className="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
        <h2 className="text-center mb-3 font-bold text-3xl">What some users are saying</h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-20 lg:gap-x-32 gap-y-12">
          <div className="relative">
           
            <div className="relative shadow-md transition-all duration-200 bg-gray-800 rounded-xl hover:shadow-xl px-6 py-8 my-6">
        
            <blockquote className="">
              <p className="text-xl font-normal">
                “As a backend developer windframe has been a huge saver. I was looking for something
                like this to prototype a lot of my designs as I am not a very good frontend
                developer. <br/>I literally just design on windframe now and export the designs to use in
                my React Code. To be able to to do that has just been fantastic for my work”
              </p>
            </blockquote>
            <p className="mt-6 text-xl font-semibold text-gray-300 sm:mt-6">Anthony C.</p>
          </div>
          </div>
          <div className="relative my-8">
            <div className="absolute -inset-1">
              <div
                className="w-full h-full rotate-180 opacity-30 blur-lg filter
                    bg-gradient-to-r
                    from-yellow-400
                    via-pink-500
                    to-green-600"
              ></div>
            </div>
          <div className="relative shadow-md transition-all duration-200 bg-gray-800 rounded-xl hover:shadow-xl px-6 py-8 ">
            <blockquote className="">
              <p className="text-xl font-normal">
                “Awesome! Just got a pro subscription and I am quite surprised to find that this is
                actually quite advanced. There are lots of settings for you to adjust margin,
                padding, fonts background colors etc. super cool :) and I love the fact that I can
                plonk my cloudinary url in, such a time saver”
                </p>
                
            </blockquote>
            <p className="mt-6 text-xl font-semibold text-gray-300 sm:mt-8">Linda M.</p>
          </div>
          </div>
        </div>
      </div>
    </section>
  );
};
