import React from 'react';

export const Hero4 = () => {
  return (
    <section className="py-12 bg-gray-900 text-gray-100 sm:py-16 lg:py-20">
      <div className="px-4 mx-auto container px-6">
        <div className="grid grid-cols-1 lg:items-center gap-y-8 lg:grid-cols-2 md:gap-y-16 lg:gap-x-16">
          <div>
            <div className="lg:text-left">
              <h2 className="text-3xl font-bold sm:text-4xl xl:text-5xl font-pj">
                Save countless hours of coding and designing using our intuitive 1000+ templates
              </h2>
              <p className="mt-4 text-base leading-7 text-gray-300 font-pj sm:mt-8">
                Windframe features a large collection of beautiful already built templates made with
                tailwind css which are regularly updated with new templates. <br /> <br /> You can
                easily edit these templates in the tailwind builder / editor to create any type of
                webpage and save countless hours redesigning from scratch
              </p>
            </div>
          </div>
          <div>
            <img className="" src={require('../../../components/images/templates1.png')} alt="" />
          </div>
        </div>
      </div>
    </section>
  );
};
