import React from 'react';

export const VideoSection = () => {
  return (
    <section className="relative pt-12 pb-24 sm:pt-16 lg:pt-20 bg-gray-900 text-gray-100">
      <div className="relative px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div className="max-w-5xl mx-auto text-center xl:max-w-6xl">
          <h2 className="text-3xl sm:text-5xl xl:text-5xl font-pj">
Build with your own Tailwind CSS UI library and unlock massive editor advantages          </h2>
          <a
            href="https://windframe.devwares.com"
            title=""
            className="
                            inline-flex
                            items-center
                            justify-center
                            px-6
                            py-3
                            mt-8
                            text-lg
                            font-bold
                            text-white
                            transition-all
                            duration-200
                            bg-transparent
                            border border-white
                            sm:mt-11
                            hover:bg-white hover:text-gray-900
                            focus:bg-white focus:text-gray-900
                            rounded-xl
                            font-pj
                            focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-white focus:ring-offset-gray-900
                        "
            role="button"
          >
            Start building for free
          </a>
        </div>
      </div>

      <div className="relative max-w-6xl w-fit mx-auto mt-16">
        <div className="absolute -inset-2">
          <div
            className="w-full h-full max-w-6xl mx-auto opacity-30 blur-lg filter 
              bg-gradient-to-r
              from-yellow-400
              via-pink-500
              to-green-600"
          ></div>
        </div>
        <div className=" mx-auto w-fit relative group sm:mt-12 lg:mx-0">
          <div className="absolute opacity-70 -inset-px rounded-xl blur-lg group-hover:opacity-100 group-hover:-inset-1
                  duration-200 bg-gradient-to-r from-yellow-400 via-pink-500 to-blue-400"></div>
          <video autoPlay muted loop className="feature__img inline-flex items-center justify-center text-base font-bold text-white px-1 py-1
                  bg-gray-900 border-transparent relative z-10 leading-7 transition-all duration-200 rounded-xl
                  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900 hover:bg-gray-600
                  focus:ring-offset-[#FFE942]">
            <source src={require('../../../components/images/windimportuilibrary.mp4')} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      </div>
    </section>
  );
};
