import React from 'react';


export const Hero03 = () => {
  return (
    <section className="py-12 bg-gray-900 text-gray-100 sm:py-16 lg:py-20">
      <div className="px-4 mx-auto container px-6">
        <div className="grid grid-cols-1 lg:items-center gap-y-8 lg:grid-cols-2 md:gap-y-16 lg:gap-x-16">
          <div className="w-fit lg:mr-8">                  
            <img className="rounded-xl" src={require('../../../components/images/invite.png')} alt="" />
          </div>
          <div>
            <div className="lg:text-left">
              <h2 className="text-3xl font-bold sm:text-4xl xl:text-5xl font-pj">
                Collaborate with other team members and get better feedback on designs and code
              </h2>
              <p className="mt-4 text-base leading-7 text-gray-300 font-pj sm:mt-8">
                Add team members to a project and get feedback on the designs, prototypes or code
                you are working on. Easily allow team members to make edits and corrections to
                projects
              </p>
            </div>

            <div className="relative w-fit mt-8 sm:mt-12">
              <div className="absolute -inset-1">
                <div className="w-full h-full max-w-sm mx-auto lg:mx-0 opacity-30 blur-lg filter bg-gradient-to-r from-yellow-400 via-pink-500 to-green-600"></div>
              </div>
              <a
                href="https://windframe.devwares.com"
                className="relative z-10 inline-flex items-center justify-center w-full px-8 py-3 text-lg font-bold text-white transition-all duration-200 bg-gray-900 border-2 border-transparent sm:w-auto rounded-xl font-pj hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900"
              > 
                Start Collaborating
              </a>
            </div>
          </div>
        
        </div>
      </div>
    </section>
  );
};
