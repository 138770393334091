import React from 'react';

export const HeroClasses = () => {
  return (
    <section className="py-12 bg-gray-900 text-gray-100 sm:py-16 lg:py-20">
      <div className="px-4 mx-auto container px-6">
        <div className="grid grid-cols-1 lg:items-center gap-y-8 lg:grid-cols-2 md:gap-y-16 lg:gap-x-16">
       
          <div>
            <div className="lg:text-left">
              <h2 className="text-3xl font-bold sm:text-4xl xl:text-5xl font-pj">
                Effortlessly work with Tailwind classes by utilizing a segmented classes view based on screen sizes.  </h2>
              <p className="mt-4 text-base leading-7 text-gray-300 font-pj sm:mt-8">
                Windframe's segmented classes view makes it so much easier to work with tailwind classes  by segmenting classes for the different screen sizes. 
                <br /><br />
This allows for a more efficient and streamlined development process, as you can quickly and easily see which classes are being used for each screen size and make adjustments as needed.     </p>
            </div>
          </div>
          <div>
            <img className="" src={require('../../../components/images/classeshero2.png')} alt="" />
          </div>
        </div>
      </div>
    </section>
  );
};
