import React from 'react';


export const Upcoming = () => {
  return (
    <section className="py-12 bg-gray-900 text-gray-100 sm:py-16 lg:py-20">
      <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div className="max-w-xl mx-auto text-center xl:max-w-2xl">
          <h2 className="text-3xl font-bold leading-tight sm:text-4xl xl:text-5xl font-pj mb-6">
            We are just getting started!
          </h2>
          <p className="mb-4">
            We are creating a tool that helps you be more productive and efficient when building
            websites and webapps
          </p>
          <p>
            Feel free to suggest new features you would like to see on windframe using our contact page
         </p>
        </div>

        <div className="grid max-w-3xl lg:max-w-4xl grid-cols-1 mx-auto mt-8 text-center gap-y-4 sm:gap-x-8 sm:grid-cols-2 lg:grid-cols-2 sm:mt-12 lg:mt-20 sm:text-left">
          <div className="overflow-hidden bg-gray-800 text-gray-100 shadow-md rounded-xl">
            <div className="p-9">
              <p className="text-4xl">🤝</p>
              <h3 className="mt-6 text-2xl font-bold sm:mt-10 font-pj">Realtime Collaboration</h3>
              <p className="mt-6 text-base text-gray-300 font-pj">
                Collaborate in realtime with other editors in a project. See what othe editors are
                doing and edit even a simple text together
              </p>
            </div>
          </div>

          <div className="relative">
            <div className="absolute -inset-1">
              <div
                className="w-full h-full rotate-180 opacity-30 blur-lg filter
                    bg-gradient-to-r
                    from-yellow-400
                    via-pink-500
                    to-green-600"
              ></div>
            </div>

            <div className="relative overflow-hidden bg-gray-800 text-gray-100 shadow-md rounded-xl h-full">
              <div className="p-9">
                <p className="text-4xl">🔗</p>
                <h3 className="mt-6 text-2xl font-bold sm:mt-10 font-pj">Integrations</h3>
                <p className="mt-6 text-base text-gray-300 font-pj">
                  Step up your designs and workflow with integrations with your favourite tools such
                  as Github, Bitbucket, Vercel etc
                </p>
              </div>
            </div>
          </div>

          {/* <div className="relative">
            <div className="absolute -inset-1">
              <div
                className="w-full h-full rotate-180 opacity-30 blur-lg filter
                    bg-gradient-to-r
                    from-yellow-400
                    via-pink-500
                    to-green-600"
              ></div>
            </div>

            <div className="relative overflow-hidden bg-gray-800 text-gray-100 shadow-md rounded-xl h-full">
              <div className="p-9">
                <p className="text-4xl">🤖</p>
                <h3 className="mt-6 text-2xl font-bold sm:mt-10 font-pj">
                 Windframe AI
                </h3>
                <p className="mt-6 text-base text-gray-300 font-pj">
                We are bringing the power of AI to the editor! We can't wait to release this and see all the awesome things it makes possible </p>
              </div>
            </div>
          </div> */}

          <div className="relative">
            <div className="absolute -inset-1">
              <div
                className="w-full h-full rotate-180 opacity-30 blur-lg filter
                    bg-gradient-to-r
                    from-yellow-400
                    via-pink-500
                    to-green-600"
              ></div>
            </div>

            <div className="relative overflow-hidden bg-gray-800 text-gray-100 shadow-md rounded-xl h-full">
              <div className="p-9">
                <p className="text-4xl">⚙️</p>
                <h3 className="mt-6 text-2xl font-bold sm:mt-10 font-pj">
                 Alpine JS
                </h3>
                <p className="mt-6 text-base text-gray-300 font-pj">
                We are bringing Alpine JS components to the editor. This allows for building more complex web applications and UIs </p>
              </div>
            </div>
          </div>

          <div className="overflow-hidden bg-gray-800 text-gray-100 shadow-md rounded-xl">
            <div className="p-9">
              <p className="text-4xl">📡</p>
              <h3 className="mt-6 text-2xl font-bold sm:mt-10 font-pj">Custom Domains</h3>
              <p className="mt-6 text-base text-gray-300 font-pj">
                Attach your own custom domain to your published projects or website on windframe
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
