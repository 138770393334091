import React from 'react';
import { Link } from 'gatsby';

export const Feature = () => {
  return (
    <section className="py-12 bg-gray-900 text-gray-100 sm:py-16 lg:py-20">
      <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div className="max-w-xl mx-auto text-center xl:max-w-2xl">
          <h2 className="text-3xl font-bold leading-tight sm:text-4xl xl:text-5xl font-pj">
            Features
          </h2>
        </div>

        <div className="grid max-w-4xl lg:max-w-6xl grid-cols-1 mx-auto mt-8 text-center gap-y-4 sm:gap-x-8 sm:grid-cols-2 lg:grid-cols-3 sm:mt-12 lg:mt-20 sm:text-left">
          <div className="overflow-hidden bg-gray-800 shadow-md rounded-xl">
            <div className="p-9">
              <p className="text-4xl">🖥</p>

              <h3 className="mt-6 text-2xl font-bold sm:mt-10 font-pj">Realtime editing using an intuitive interface</h3>
              <p className="mt-6 text-base text-gray-300 font-pj">
                Import your websites and templates and edit them visually (Easily add, remove or edit anything from your templates or website using an intuitive interface and seeing how it looks in realtime)
              </p>
            </div>
          </div>

          <div className="relative">
            <div className="absolute -inset-1">
              <div
                className="w-full h-full rotate-180 opacity-30 blur-lg filter  
                    bg-gradient-to-r
                    from-yellow-400
                    via-pink-500
                    to-green-600"
              ></div>
            </div>

            <div className="relative overflow-hidden bg-gray-800 shadow-md rounded-xl h-full">
              <div className="p-9">
                <p className="text-4xl">🤖</p>


                <h3 className="mt-6 text-2xl font-bold sm:mt-10 font-pj">Windframe AI</h3>
                <p className="mt-6 text-base text-gray-300 font-pj">
                  Experience the future of UI design with Windframe AI. Unlock a world of possibilities for creating beautiful and cohesive designs in no time.
                </p>
              </div>
            </div>
          </div>

          <div className="overflow-hidden bg-gray-800 shadow-md rounded-xl">
            <div className="p-9">
              <p className="text-4xl">🔌</p>

              <h3 className="mt-6 text-2xl font-bold sm:mt-10 font-pj">
                Prebuilt Tailwind CSS Templates
              </h3>
              <p className="mt-6 text-base text-gray-300 font-pj">
                Windframe features a collection of well crafted tailwind css templates which are
                regularly updated with new templates. You can easily kick of any project with the
                help of numerous sections in the tailwind builder.
              </p>
            </div>
          </div>

          <div className="relative">
            <div className="absolute -inset-1">
              <div
                className="w-full h-full rotate-180 opacity-30 blur-lg filter  
                    bg-gradient-to-r
                    from-yellow-400
                    via-pink-500
                    to-green-600"
              ></div>
            </div>

            <div className="relative overflow-hidden bg-gray-800 shadow-md rounded-xl h-full">
              <div className="p-9">
                <p className="text-4xl">👀</p>


                <h3 className="mt-6 text-2xl font-bold sm:mt-10 font-pj">
Render in various screensizes and devices                </h3>
                <p className="mt-6 text-base text-gray-300 font-pj">
                  Easily see how your website renders in various screen sizes and devices using different screen sizes available on windframe
                </p>
              </div>
            </div>
          </div>
          <div className="overflow-hidden bg-gray-800 shadow-md rounded-xl">
            <div className="p-9">
              <p className="text-4xl">🧩</p>


              <h3 className="mt-6 text-2xl font-bold sm:mt-10 font-pj">Segmented classes
</h3>
              <p className="mt-6 text-base text-gray-300 font-pj">
                See all your tailwind classes segmented in the classes box into responsive and regular classes.
                Easily click on a class to edit it and a delete icon beside it to remove it. Remove or add classes easily to see how they affect you designs.
              </p>
            </div>
          </div>

          <div className="overflow-hidden bg-gray-800 shadow-md rounded-xl">
            <div className="p-9">
              <p className="text-4xl">✍️</p>


              <h3 className="mt-6 text-2xl font-bold sm:mt-10 font-pj">Easily undo and redo changes</h3>
              <p className="mt-6 text-base text-gray-300 font-pj">
                Go back and forth your changes or updates easily using an undo and redo button.
              </p>
            </div>
          </div>
          <div className="relative">
            <div className="absolute -inset-1">
              <div
                className="w-full h-full rotate-180 opacity-30 blur-lg filter  
                    bg-gradient-to-r
                    from-yellow-400
                    via-pink-500
                    to-green-600"
              ></div>
            </div>

            <div className="relative overflow-hidden bg-gray-800 shadow-md rounded-xl h-full">
              <div className="p-9">
                <p className="text-4xl">📍</p>


                <h3 className="mt-6 text-2xl font-bold sm:mt-10 font-pj">History of Edits</h3>
                <p className="mt-6 text-base text-gray-300 font-pj">
                  Save different points in history of your projects and easily go back and forth your changes. This enables you to restore your designs to a previous point in
                  time
                </p>
              </div>
            </div>
          </div>

          <div className="relative">
            <div className="absolute -inset-1">
              <div
                className="w-full h-full rotate-180 opacity-30 blur-lg filter  
                    bg-gradient-to-r
                    from-yellow-400
                    via-pink-500
                    to-green-600"
              ></div>
            </div>

            <div className="relative overflow-hidden bg-gray-800 shadow-md rounded-xl h-full">
              <div className="p-9">
                <p className="text-4xl">👌</p>

                <h3 className="mt-6 text-2xl font-bold sm:mt-10 font-pj">
                  Well structured code
                </h3>
                <p className="mt-6 text-base text-gray-300 font-pj">
                  Generate well structured and indented code for your websites and templates when exporting them using windframe.
                </p>
              </div>
            </div>
          </div>

          <div className="relative">
            <div className="absolute -inset-1">
              <div
                className="w-full h-full rotate-180 opacity-30 blur-lg filter  
                    bg-gradient-to-r
                    from-yellow-400
                    via-pink-500
                    to-green-600"
              ></div>
            </div>

            <div className="relative overflow-hidden bg-gray-800 shadow-md rounded-xl h-full">
              <div className="p-9">
                <p className="text-4xl">⚡️</p>

                <h3 className="mt-6 text-2xl font-bold sm:mt-10 font-pj">Export to various frameworks</h3>
                <p className="mt-6 text-base text-gray-300 font-pj">
                  Go from having just HTML version of your website to exporting the React and Vuejs code for your template/website/component you import into windframe.
                </p>
              </div>
            </div>
          </div>

          {/* <div className="overflow-hidden bg-gray-800 shadow-md rounded-xl">
            <div className="p-9">
              <p className="text-4xl">✨</p>

              <h3 className="mt-6 text-2xl font-bold sm:mt-10 font-pj">
                Responsive specific classes
              </h3>
              <p className="mt-6 text-base text-gray-300 font-pj">
                Use the responsive classes tab to set very specific responsive classes and see which responsive classes each applied to each element of your website.
              </p>
            </div>
          </div> */}
        </div>
      </div>
    </section>
  );
};
