import React from 'react';


export const Hero5 = () => {
  return (
    <section className="py-12 bg-gray-900 text-gray-100 sm:py-16 lg:py-20">
      <div className="px-4 mx-auto container px-6">
        <div className="grid grid-cols-1 lg:items-center gap-y-8 lg:grid-cols-2 md:gap-y-16 lg:gap-x-16">
      
          <div>
            <div className="lg:text-left">
              <h2 className="text-3xl font-bold sm:text-4xl xl:text-5xl font-pj">
                Save different versions of your project history and easily switch between them.
              </h2>
              <p className="mt-4 text-base leading-7 text-gray-300 font-pj sm:mt-8">
                Windframe’s version history makes it possible  to save different versions of your projects and easily switch between them, making it easy to keep track of your progress and experiment with different design ideas.
                <br /><br /> 
                This is particularly useful when working on larger projects or when you want to test out different design options without losing progress. With the version history, you can easily revert to older versions of your project if needed and keep track of your work over time.
              </p>
            </div>
          </div>
          <div>
            <div className="relative mx-auto">
              <div className="lg:max-w-6xl lg:mx-auto">
                <div className=" mx-auto w-fit relative group sm:mt-12 lg:mx-0">
                  <div className="absolute opacity-70 -inset-px rounded-xl blur-lg group-hover:opacity-100 group-hover:-inset-1
                  duration-200 bg-gradient-to-r from-yellow-400 via-pink-500 to-blue-400"></div>
                  <video autoPlay muted loop className="feature__img inline-flex items-center justify-center text-base font-bold text-white px-1 py-1
                  bg-gray-900 border-transparent relative z-10 leading-7 transition-all duration-200 rounded-xl
                  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900 hover:bg-gray-600
                  focus:ring-offset-[#FFE942] origin-left rotate-2">
                    <source
                      src={require('../../../components/images/windhistory.mp4')}
                      type="video/mp4"
                    />
                  </video>
                </div>
              </div>
            </div>

          </div> 
        </div>
      </div>
    </section>
  );
};
