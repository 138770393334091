import React from 'react';
import styled from 'styled-components';

import '../../styles/scss/style.scss';

const Wrapper = styled.p`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-row-gap: 2rem;

  @media (max-width: 768px) {
    grid-template-columns: 1fr 1fr;
  }
`;

const Number = styled.p`
  font-weight: 700;
  font-size: 3rem;
  color: rgb(29 78 216);
  text-align: center;
  margin-bottom: 0.5rem;

  @media (max-width: 768px) {
    font-size: 2rem;
  }
`;

const Text = styled.p`
  text-align: center;
  text-transform: uppercase;
  color: rgb(209 213 219);
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 500;
  letter-spacing: 0.1em;

  @media (max-width: 768px) {
    font-size: 0.85rem;
  }
`;

const TextWrapper = styled.p`
  text-align: center;
  border-right: ${({ border }) => (border ? '1px solid #ccc' : '')};
  @media (max-width: 768px) {
    border-right: none;
  }
`;

export const Stats = () => {
  return (
    <div className="container">
      <div className="mr-auto ml-auto py-5 px-3">
        <Wrapper>
          <TextWrapper border>
            <Number>10K+</Number>
            <Text>Designs Exported</Text>
          </TextWrapper>
          <TextWrapper border>
            <Number>3K+</Number>
            <Text>Saved Projects</Text>
          </TextWrapper>
          <TextWrapper border>
            <Number>5K+</Number>
            <Text>Users</Text>
          </TextWrapper>
          <TextWrapper>
            <Number>1000+</Number>
            <Text>Templates</Text>
          </TextWrapper>
        </Wrapper>
      </div>
    </div>
  );
};
