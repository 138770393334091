import styled from "styled-components";

export const StyledWrapper = styled.section`
display: flex;
justify-content: center;
margin-bottom: 50px !important;
.box {
  display: flex;
  flex-direction: column;
  padding: 20px 0;
  margin: 0 auto;
  width: 95%;
  h1 {
    text-align: center;
    font-weight: 500;
    padding-bottom: 20px;
    color:black;
    margin-bottom:3rem;
  }
  .header {
    text-align: center;
    font-size: 50px;
    font-weight: bold;
    margin: 0px 0px;
  }
  .body {
    text-align: center;
    font-size: 20px;
    margin: 0;
  }
  ul {
    margin: 40px auto;
    list-style: none;
    text-decoration: none;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .nav-item {
      margin: 0px auto;
      font-size: 20px;
      padding: 0px;
      color: #d0c9d6;
      text-transform: uppercase;
      cursor: pointer;
      .nav-link {
        color: #b4b4b4;
      }
      .nav-link.active {
        color: #333;
        border-top: none;
        border-left: none;
        border-right: none;
      }
    }
    .active {
      color: #333333 !important;
      border-bottom: 3px solid #6979f8 !important;
    }
  }
  .tabcontent {
    display: none;
  }
  .tabcontent.active {
    display: block;
  }
  @media (max-width: 768px) {
    .header {
      font-size: 35px;
    }
    ul {
      margin: 30px auto;
      .nav-item {
        font-size: 17px !important;
        padding: 0 15px !important;
        &:hover {
          border: none !important;
        }
        &:focus {
          border: none !important;
        }
      }
    }
  }
}
@media (max-width:768px) {
  .box {
    width:100%;
  }
}
`;

export const TabContent = styled.div`
  text-align: center;
  padding: 0px 3% 0px 3%;
  font-size: 17px;
  line-height: 1.7;
`;

export const Header = styled.h3`
  margin-bottom: 1rem;
`;

export const Content = styled.div``;
